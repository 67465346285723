import type { FC } from "react";
import React, { useEffect } from "react";
import AppPageTitle from "src/ui/components/AppPageTitle/AppPageTitle";
import ChangeShippingAddress from "src/ui/components/ChangeShippingAddress/ChangeShippingAddress";
import { useNavigate, useParams } from "react-router-dom";
import Data from "src/ui/components/Data/Data";
import ProfileCubit from "src/state/ProfileCubit/ProfileCubit";
import { useBloc } from "src/state/state";
import OverlayBackButton from "src/ui/components/StyledComponents/OverlayBackButton";
import { PROFILE_URL } from "src/ui/components/ProfileOverview/ProfileOverview";
import translate from "src/lib/translate";
import type { TranslationKey } from "src/types/translationKey";

enum ShippingInformationAction {
  initial = "",
  change = "change"
}

const SHIPPING_PATH = "/app/profile/shipping";

const ShippingInformation: FC = () => {
  const { action = "" } = useParams<{ action?: ShippingInformationAction }>();
  const [profile, { loadAddress }] = useBloc(ProfileCubit);
  const address = profile.data.addressData;
  const navigate = useNavigate();

  useEffect(() => {
    void loadAddress();
  }, []);

  const backUrl =
    action === ShippingInformationAction.change ? SHIPPING_PATH : PROFILE_URL;

  const data: [TranslationKey, string | undefined][] = [
    ["firstName", address?.firstName],
    ["lastName", address?.lastName],
    ["street", address?.street],
    ["aptSuite", address?.aptSuite],
    ["city", address?.city],
    ["state", address?.state],
    ["zip", address?.zip]
  ];

  return (
    <>
      <OverlayBackButton show fallbackUrl={backUrl} />
      <AppPageTitle title={translate("shipping_information_title")} />
      <nine-spacer s={"xl"}></nine-spacer>

      {action === ShippingInformationAction.initial && (
        <>
          <Data.Table
            title={translate("shipping_information_title")}
            action={{
              onAction: () =>
                navigate(`${SHIPPING_PATH}/${ShippingInformationAction.change}`)
            }}
          >
            {data.map(([title, v], index) => (
              <Data.Row key={index} title={translate(title)} data={v} />
            ))}
          </Data.Table>
        </>
      )}
      {action === ShippingInformationAction.change && <ChangeShippingAddress />}
    </>
  );
};

export default ShippingInformation;
