import type { FC } from "react";
import React from "react";
import { useNavigate } from "react-router-dom";
import Path from "src/constants/path";
import { createAppPath } from "src/lib/createAppPath";
import { TrackEvent, TrackType } from "src/state/Track/TrackCubit";
import { toast, tracker, userPreferences } from "src/state/state";
import ShippingForm from "src/ui/components/ShippingForm/ShippingForm";
import Track from "src/ui/components/Track/Track";
import { InputFocusVirtualKeyboardPadding } from "../StyledComponents/InputFocusVirtualKeyboardPadding";

const ChangeShippingAddress: FC = () => {
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();

  const handleSuccess = (): void => {
    tracker.track(TrackEvent.changeShippingAddress, {
      type: TrackType.complete
    });
    toast.success("shipping_address_change_success");
    navigate(createAppPath(Path.profile));
    void userPreferences.loadUserPreferences();
  };

  return (
    <>
      <Track event={TrackEvent.changeShippingAddress} type={TrackType.start} />

      <InputFocusVirtualKeyboardPadding>
        <ShippingForm
          onSuccess={handleSuccess}
          formClass=""
          loading={loading}
          isShippingUpdate={true}
          onSubmitStart={(): void => setLoading(true)}
        />
      </InputFocusVirtualKeyboardPadding>
    </>
  );
};

export default ChangeShippingAddress;
